<template>
    <a-drawer
            :title="title"
            :visible="visible"
            :placement="'top'"
            @close="$emit('close')"
            :height="'100%'"
            :wrapClassName="'addClasses'"
    >
        <div class="content">
            <a-form
                    :form="form"
                    v-bind="formItemLayout"
            >
                <a-form-item label="上传封面图">
                    <a-upload
                            name="avatar"
                            list-type="picture-card"
                            :show-upload-list="false"
                            :customRequest="customRequest"
                            v-decorator="['fileImg',
                            { rules: [{ required: true, message: '请上传图片' }] },
                        ]"
                    >
                        <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                        <div v-else>
                            <a-icon :type="loading ? 'loading' : 'plus'" style="font-size:30px;color:#dcdcdc"/>
                            <div>上传图片</div>
                        </div>
                    </a-upload>
                    <span>大小不超过10M,格式为jpg</span>
                </a-form-item>
                <a-form-item label="通知标题">
                    <a-input
                            v-decorator="['title', { initialValue: formData.title, rules: [{ required: true, message: '请输入通知标题' }] }]"
                    />
                </a-form-item>
                <a-form-item label="通知人">
                    <a-input
                            v-decorator="['name', { initialValue: formData.name, rules: [{ required: true, message: '请输入通知人' }] }]"
                    />
                </a-form-item>
                <a-form-item label="通知内容" v-bind="formItemLayout" style="position: relative;z-index: 1;">
                    <Editor @input="handleInput" placeholder="请输入通知内容" style="min-height: 200px"
                            v-decorator="['content', { initialValue: formData.content, rules: [{ required: true, message: '请输入通知内容' }] }]"
                    ></Editor>
                </a-form-item>
            </a-form>
            <div style="text-align:right;padding-top:30px;padding-right:20%">
                <a-button class="mr20px" style="width:100px" @click="$emit('close')">取消</a-button>
                <a-button type="primary"  style="width:100px" @click="submit">立即添加</a-button>
            </div>
        </div>
    </a-drawer>
</template>
<script>
    import { uploadImg } from '@/libs/api'
    import * as http from '@/libs/practice'
    import Editor from '@/components/wangeditor'
    export default {
        name: 'addNotice',
        components:{ Editor },
        props: ['visible', 'options', 'data', 'edit'],
        beforeCreate() {
            this.form = this.$form.createForm(this);
        },
        data(){
            return{
                formItemLayout: {
                    labelCol: { span: 8 },
                    wrapperCol: { span: 10 },
                },
                imageUrl: '',
                user_type: 1,
                detail_type: 1,
                showP:false,
                loading: false,
                subjectList: [],
                ProfessList:[],
                detailUrl:'',
                title:'添加通知',
                showSchool: false,
                showBpid: false,
                SchoolList: [],
                BpidList: [],
                detailfileImg:[],
                detail_bg_url:'',
                formData:{
                    name:'',content:'',title:'',material:'',cate_id:'',user_num:'',
                    sids:[],detail_url:'',old_price:'',now_price:'',exam_time:null ,valid_time:'',
                    pids: '', school: '', b_pids:''
                }
            }
        },
        watch:{
            visible(val){
                if(!val){
                    this.reset()
                }else{
                    this.init().then(()=>{
                        if(this.edit){
                            Object.assign(this.formData, this.data);
                            this.title = '编辑通知'
                            this.imageUrl = this.data.img
                            setTimeout(()=>{
                                this.form.setFieldsValue({'fileImg':[{uid:-1, name: 'image.png',status: 'done', url: this.data.img}]})
                                this.form.setFieldsValue({'detailfileImg':[{uid:2, name: 'imageBg.png',status: 'done', url: this.data.detail_bg_url}]})
                                if(this.detail_type == 2){
                                    this.form.setFieldsValue({'detailImg':[{uid:1, name: 'detailimage.png',status: 'done', url: this.data.detail_url}]})
                                }
                            })
                        }else{
                            this.title = '新增通知'
                        }
                    })
                }
            }
        },
        methods:{
            handleInput (html) {
                this.formData.content = html
            },
            reset(){
                this.form.resetFields()
                this.imageUrl = ''
                this.detail_bg_url = ''
                this.user_type = 1
                this.detail_type = 1
                this.subjectList = []
                this.loading = false
                this.detailUrl = ''
                this.showP = false
                this.showSchool = false
                this.formData = {
                    name:'',content:'',title:'',material:'',cate_id:'',user_num:'',
                    sids:[],detail_url:'',old_price:'',now_price:'',exam_time:null,valid_time:'',
                    pids:'', school: '',b_pids:''
                }
            },
            init(){
                return new Promise((resolve,reject) => {
                    Promise.all([]).then(res=>{
                        resolve()
                    }).catch(reject)
                })
            },
            changeCate(){
                setTimeout(()=>{
                    if(this.form.getFieldValue('sids')) this.form.setFieldsValue({'sids': []})
                    this.subjectList = this.allSubject.filter(el=>el.cate_id == this.form.getFieldValue('cate_id'))
                })
            },
            customRequestVideo(data){
                let formData = new FormData()
                formData.append('file',data.file)
                uploadImg(formData).then(res=>{
                    if(res){
                        this.$message.info('上传完成');
                        this.formData.material = res.fileUrl;
                    }
                },error=>{
                    console.log(error)
                })
            },
            customRequest(data){
                let formData = new FormData()
                formData.append('file',data.file)
                uploadImg(formData).then(res=>{
                    if(res){
                        this.imageUrl=res.fileUrl
                    }
                },error=>{
                    console.log(error)
                })
            },
            customRequestDetail(data){
                let formData = new FormData()
                formData.append('file',data.file)
                uploadImg(formData).then(res=>{
                    if(res){
                        this.detailUrl=res.fileUrl
                    }
                },error=>{
                    console.log(error)
                })

            },
            customRequestDetailBg(data){
                let formData = new FormData()
                formData.append('file',data.file)
                uploadImg(formData).then(res=>{
                    if(res){
                        this.detail_bg_url=res.fileUrl
                    }
                },error=>{
                    console.log(error)
                })
            },
            submit(){
                this.form.validateFields((err, values) => {
                    if(err) return;
                    let request = this._.cloneDeep(values)
                    delete request.fileImg
                    request.img = this.imageUrl

                    if(this.edit){
                        request.id = this.data.id
                        http.edit_notice(request).then(res=>{
                            this.$emit('close')
                            this.$emit('update')
                        }).catch(error=>{
                            console.log(error)
                        })
                    }else{
                        http.add_notice(request).then(res=>{
                            this.$emit('close')
                            this.$emit('update')
                        }).catch(error=>{
                            console.log(error)
                        })
                    }
                })
            },
            vaildatorPrice(rule, value, callback){
            }
        }
    }
</script>
<style lang="less" scoped>
    .sTable{
        width: 420px;
        border: 1px solid #eee;
        .th{
            background: #eeeeee;
            height: 32px;
            display: flex;
            align-items: center;
            text-align: center;
            >div{
                flex: 1;
            }
        }
        .tbody{
            padding: 20px 10px;
            display: flex;
            align-items: center;
            >div{
                flex: 1;
            }
        }
    }
</style>
