<template>
    <div class="pd20x flex1">
        <AddNotice :visible="openClasses" @close="openClasses = false" :options="options" :data="currentItem" :edit="editClass" @update="getData"></AddNotice>
        <div class="contentBox h100pct ofA">
            <div class="filter" style="padding:10px 20px">
                <div>通知数量（{{data.length}}条） </div>
                <div>
                    <label>搜索：</label>
                     <a-input-search v-model="filter.keywords" placeholder="搜索通知" style="width: 200px" @search="()=>{pagination.page = 1;getData()}" class="mr10px"/>
                    <a-button type="primary" @click="openClasses = true; currentItem = null; editClass = false">添加通知</a-button>
                </div>
            </div>
            <a-table
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                :rowKey="'id'"
                @change="changePage"
            >
                <template slot="create_time" slot-scope="text">
                    {{formatDate(text*1000)}}
                </template>
                <template slot="operation" slot-scope="text, record">
                    <i class="z-icon editB" @click="openClasses = true; currentItem = record; editClass = true"></i>
                    <i class="z-icon delete" @click="deleteItem(record.id)"></i>
                </template>
            </a-table>
        </div>
    </div>
</template>
<script>
const columns = [{
    title: '序号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' },
    align:'center'
  },
  {
    title: '通知标题',
    dataIndex: 'title',
    align:'center'
  },
    {
        title: '通知人',
        dataIndex: 'name',
        align:'center'
    },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    align:'center',
    scopedSlots: { customRender: 'create_time' },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];
import {formatDate} from '@/libs/moment'
import AddNotice from '@/components/practice/addNotice'
import * as http from '@/libs/practice'
import { routerlink } from '@/mixins/routerlink'
export default {
    name: 'notice',
    components: { AddNotice },
    mixins: [routerlink],
    data(){
        return{
            options: [],
            columns,
            filter:{
                cate_id: 0,
                keywords:''
            },
            pagination:{
                page: 1,
                size: '15',
                pageSize: 15
            },
            allSubject:[],
            data: [],
            areaval: '',
            openClasses: false,
            currentItem:'',
            editClass:false
        }
    },
    mounted(){
        this.$nextTick(()=>{
            Promise.all([]).then(res=>{
                this.getData()
            })
        })
    },
    methods:{
        formatDate,
        operateSids(text, record){
                let arr = text.split(',')
                let result = []
                arr.forEach(el=>{
                    let target = this.allSubject.filter(item=> item.id == el)
                    if(target.length){
                        result.push(target[0].name)
                    }
                })
            return result.length?result.join(','):'--'
        },
        getData(){
            let request = {...this.filter,...this.pagination}
            if(this.filter.cate_id == 0){
               request.cate_id = null
            }
            http.get_notice_list(request).then(res=>{
                const pagination = { ...this.pagination }
                pagination.total = res.total;
                this.data = res.data
                this.pagination = pagination;

            }).catch(error=>{
                console.log(error)
            })
        },
        handleChange(){

        },
        deleteItem(id){
            let _this = this
            this.$confirm({
                title: '是否确定删除该条信息?',
                centered: true,
                onOk() {
                    http.del_notice({id}).then(res=>{
                        let {page, pagesize, total} = _this.pagination
                        if(page>1 && Number(page-1)* 15 == total - 1){
                            _this.pagination.page = Number(page-1)
                        }
                        _this.getData()
                    }).catch(error=>{
                        console.log(error)
                    })
                },
            });
        },
        changePage({current}){
            this.pagination.page = current
            this.getData()
        },
        hideModal(){

        },
        onChange(){

        },
    }
}
</script>
<style lang="less" scoped>
.imgWrap{
    width: 120px;
    height: 70px;
    overflow: hidden;
    border-radius: 4px;
    margin: auto;
    img{
        width: 100%;
    }
}
</style>
